<!--
 * @Description: 账单详情
 * @Author: ChenXueLin
 * @Date: 2021-09-24 13:41:39
 * @LastEditTime: 2021-10-09 15:25:05
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content :btnType="1" btnText="任务单状态:进行中">
            <template v-slot:rightButtons>
              <el-button type="primary">审核账单</el-button>
              <el-button type="primary">撤销账单</el-button>
            </template>
          </detail-title-content>
          <!-- 基本信息 -->
          <template>
            <!-- 基本信息 start -->
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                label-width="140px"
                label-position="right"
                class="formBox"
              >
                <el-row>
                  <el-col :span="6">
                    <el-form-item label="项目ID">
                      1234567
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="项目名称">
                      安装任务
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="负责人"> 张三 </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="联系人"> 张三 </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="创建人">
                      2021-08-25
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="创建时间">
                      发货计费
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="备注"> 张三 </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <!-- 基本信息 end -->
          </template>

          <!-- 项目人员start -->
          <table-detail title="账单明细" height="400px">
            <template v-slot:buttonBox>
              <el-button type="primary">申请减免</el-button>
              <el-button type="primary">添加费用</el-button>
              <el-button type="primary">删除费用</el-button>
            </template>
          </table-detail>
          <!-- 项目人员end -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
// import { exportXlsx } from "@/utils/util";
import detailTitleContent from "@/components/detailTitleContent";
import tableDetail from "@/components/tableDetail.vue";
export default {
  name: "projectDetail",
  data() {
    return {
      activeName: "second"
    };
  },
  mixins: [base, listPage, listPageReszie],
  components: {
    detailTitleContent,
    tableDetail
  },
  created() {},
  mounted() {},

  methods: {}
};
</script>
<style lang="scss" scoped>
.baseInfo-box {
  padding: 15px;
  box-sizing: border-box;
}
</style>
