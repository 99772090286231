<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2021-09-10 10:51:42
 * @LastEditTime: 2022-02-22 14:14:02
 * @LastEditors: ChenXueLin
-->
<template>
  <section class="table-wrapper">
    <div class="table-box">
      <div class="edit-title">{{ title }}</div>
      <div class="btn-box">
        <slot name="buttonBox"></slot>
      </div>
    </div>
    <el-table
      border
      :height="height"
      :data="tableData"
      highlight-current-row
      class="elTable"
    >
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in columnData"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
      </el-table-column>
    </el-table>
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
  </section>
</template>

<script>
import listPage from "@/mixins/list-page";
export default {
  name: "",
  components: {},
  data() {
    return {
      searchForm: {
        orderNo: "",
        typeId: "",
        secondType: "",
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      columnData: [
        {
          fieldName: "label",
          display: true,
          fieldLabel: "标签",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "renwudandao",
          display: true,
          fieldLabel: "任务单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "kehumingcheng",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "kehujiancheng",
          display: true,
          fieldLabel: "客户简称",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "suoshuxiangmu",
          display: true,
          fieldLabel: "所属项目",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "orderType",
          display: true,
          fieldLabel: "任务单类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "status",
          display: true,
          fieldLabel: "任务单状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "orderCount",
          display: true,
          fieldLabel: "任务项总数",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "未分配",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "待指派",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "待作业",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "作业中",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "异常完成",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "已完成",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "任务所属大区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "所属地区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "要求完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "任务完结时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "创建人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noDis",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [
        {
          label: ["有变更", "有更近", "有退会"],
          renwudandao: 1233,
          noDis: "111",
          status: 1,
          id: 1,
          pid: 0,
          ruleItemData: [
            {
              label: ["有变更", "有更近", "有退会"],
              renwudandao: "AaA3",
              noDis: "111",
              status: 1,
              id: 2,
              pid: 0
            }
          ]
        },

        {
          label: ["有变更", "有更近", "有退会"],
          noDis: "111",
          renwudandao: 595,
          status: 2,
          id: 3
        },
        {
          label: ["有变更", "有更近", "有退会"],
          noDis: "111",
          status: 3,
          id: 4
        }
      ] // 表格数据
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: "200px"
    }
  },
  mixins: [listPage],
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(curPage) {
      this.searchForm.pageIndex = curPage;
      // this.queryList();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageIndex = 1;
      this.searchForm.pageSize = pageSize;
      // this.queryList();
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.table-box {
  .btn-box {
    padding: 15px;
    box-sizing: border-box;
  }
}
</style>
